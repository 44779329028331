@import "../../../assets/variables.scss";

.projects {
  .projects-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, minmax(480px, 1fr));
    gap: 40px;

    .projects-grid-item {
      background-color: $grey-two;
      width: 100%;
      border-radius: 7px;
      overflow: hidden;
      display: grid;
      grid-template-rows: 280px 1fr;
      box-sizing: border-box;

      &.placeholder {
        height: 570px;
        background-color: $grey-two;
      }

      .projects-grid-item-img {
        position: relative;
        color: $black;
        height: 280px;

        .blur {
          position: absolute;
          background-color: rgba(255, 255, 255, 0.7);
          width: 100%;
          height: 280px;
          font-weight: bold;
          font-size: $f-30;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          height: 280px;
          width: 100%;
          object-fit: cover;
        }
      }

      .projects-grid-item-info {
        padding: $spacing-lg calc($spacing-lg * 1.2);
        display: grid;

        h2 {
          margin: 0 0 $spacing-md 0;
        }

        ul {
          list-style-type: none;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          li {
            background-color: $grey;
            padding: $spacing-md;
          }
        }

        .project-links {
          display: flex;
          justify-content: flex-end;
          gap: 15px;
          align-self: flex-end;
          justify-self: flex-end;

          a {
            color: $white;
            text-decoration: none;
            font-size: $f-24;

            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1063px) {
  .projects-grid {
    grid-template-columns: minmax(480px, 1fr) !important;

    .projects-grid-item-img {
      img {
        object-position: top;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .projects-grid {
    grid-template-columns: minmax(300px, 1fr) !important;
  }
}

@media only screen and (max-width: 550px) {
  .projects-grid {
    padding: 0 $spacing-sm;
    grid-template-columns: minmax(300px, 1fr) !important;
  }
}
