@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./variables.scss";

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: $bg-main;

  .app {
    width: 100%;
    height: 100%;
    color: $white;
    display: grid;
    grid-template-rows: 1fr 100px;
    grid-template-columns: 1fr;
    justify-content: center;
    box-sizing: border-box;

    .container {
      width: 100%;
      max-width: $max-width;
      display: flex;
      flex-direction: column;
      justify-self: center;
      padding: 70px 20px calc($spacing-xl * 2) 20px;
      box-sizing: border-box;

      .section {
        padding-top: calc($spacing-xl * 2);

        .header {
          font-size: $f-30;
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1063px) {
  .section {
    .header {
      text-align: center;
    }
  }
}
@media only screen and (max-width: 550px) {
  .container {
    padding: 70px 0 calc($spacing-xl * 2) 0 !important;
  }
}
