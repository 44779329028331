@import "../../../assets/variables.scss";

.header-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: $bg-secondary;
  z-index: 10;
  top: 0;

  .header {
    height: 100%;
    width: 100%;
    max-width: $max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-xl;
    box-sizing: border-box;

    .header-logo {
      color: $white;
      font-weight: bold;
      text-decoration: none;
      font-size: $f-30;
      user-select: none;

      span {
        color: $blue;
        font-size: $f-30;
      }
    }

    .header-links {
      display: grid;
      grid-auto-flow: column;
      gap: $spacing-xl;
      list-style-type: none;
      height: 100%;
      padding: 0;

      .header-link {
        display: grid;
        align-items: center;

        a {
          color: $white;
          text-decoration: none;
          font-weight: bold;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .header {
    justify-content: center !important;

    .header-links {
      display: none !important;
    }
  }
}
