// COLORS
$white: #fff;
$black: #000;
$red: #fe4f47;
$green: #67bd7f;
$blue: #438efc;
$grey: #4e4f58;
$grey-two: #2a2c3b;
$bg-main: #201f2f;
$bg-secondary: #191828;

// FONT SIZES
$f-32: 32px;
$f-30: 30px;
$f-24: 24px;
$f-20: 20px;
$f-18: 18px;
$f-16: 16px;
$f-14: 14px;
$f-12: 12px;
$f-10: 10px;

// SPACING
$spacing-xs: 2px;
$spacing-sm: 4px;
$spacing-md: 8px;
$spacing-lg: 16px;
$spacing-xl: 32px;
$max-width: 1064px;
