@import "../../../assets/variables.scss";

.contact {
  background-color: $bg-secondary;
  padding: $spacing-xl !important;
  margin-top: calc($spacing-xl * 2);
  border-radius: 7px;

  h1 {
    text-align: center;
    margin: 0 0 $spacing-xl 0;
    font-size: $f-30;
  }

  .contact-buttons {
    display: flex;
    gap: 40px;
    justify-content: center;

    a {
      color: $white;
      height: 50px;
      width: 130px;
      box-sizing: border-box;
      padding: $spacing-md $spacing-xl;
      font-size: $f-20;
      text-decoration: none;
      background-color: $blue;
      display: grid;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-weight: bold;
      display: grid;
      grid-template-columns: min-content min-content;
      gap: 10px;
    }
  }
}
