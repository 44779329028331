@import "../../../assets/variables.scss";

.skills {
  background-color: $bg-secondary;
  padding: $spacing-xl !important;
  margin-top: calc($spacing-xl * 2);
  border-radius: 7px;

  .header {
    text-align: center;
  }

  .skills-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: 10px;
    justify-content: center;

    .skills-list-item {
      display: grid;
      grid-template-columns: min-content min-content;
      gap: 7px;
      align-items: center;
      width: fit-content;
      min-width: 140px;
      background-color: $bg-main;
      padding: $spacing-md;
      box-sizing: border-box;
      border: 1px solid $grey;
      border-radius: 7px;

      &.placeholder {
        height: 58px;
        background-color: $bg-main;
      }

      img {
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 505px) {
  .skills {
    padding: $spacing-xl $spacing-md !important;

    .skills-list-item {
      grid-template-columns: min-content !important;
      min-width: fit-content !important;

      span {
        display: none;
      }
    }
  }
}
