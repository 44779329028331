@import "../../../assets/variables.scss";

.about {
  display: grid;
  grid-template-columns: 60% 40%;
  padding-top: 140px !important;
  box-sizing: border-box;

  .about-left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .about-intro {
      display: flex;
      flex-direction: column;

      .about-intro {
        width: fit-content;
        font-weight: bold;
        font-size: calc($f-32 * 1.3);

        &.secondary {
          color: $blue;
        }
      }
    }
    .about-text {
      width: 80%;
      line-height: 1.7rem;
      padding: $spacing-md 0;
    }

    .follow {
      span {
        font-weight: bold;
        font-size: $f-20;
        color: $grey;
      }

      .follow-links {
        display: flex;
        list-style-type: none;
        padding: 0;
        display: grid;
        grid-auto-flow: column;
        gap: $spacing-xl;
        width: fit-content;

        .follow-link {
          a {
            color: $white;
            font-size: $f-30;

            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }

  .about-right {
    display: grid;

    .about-img {
      width: 270px;
      height: 270px;
      background-color: $blue;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      justify-self: flex-end;

      img {
        position: absolute;
        bottom: -18px;
        left: 7px;
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .about {
    display: grid;
    grid-template-columns: 100%;
    gap: 15px;

    .about-left {
      display: grid;

      .about-intro {
        justify-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .about-text {
        justify-self: center;
        text-align: justify;
        text-justify: inter-word;
      }
    }

    .about-right {
      order: -1;
      width: fit-content;
      justify-self: center;
    }

    .follow {
      justify-self: center;
      display: grid;

      span {
        justify-self: center;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .about-intro {
    font-size: $f-32 !important;
  }
}
