@import "../../../assets/variables.scss";

.certifications {
  .certifications-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, minmax(320px, 320px));
    gap: 30px;

    .certifications-grid-item {
      background-color: $grey-two;
      display: grid;
      grid-template-rows: 190px 1fr;
      border-radius: 7px;
      overflow: hidden;

      &.placeholder {
        height: 374px;
        background-color: $grey-two;
      }

      img {
        width: 100%;
      }

      .certification-info {
        height: 100%;
        display: grid;
        padding: 0 $spacing-md $spacing-md $spacing-md;
        box-sizing: border-box;
        z-index: 2;
        background-color: $grey-two;

        .title {
          color: $white;
          text-decoration: none;
          height: fit-content;

          h3 {
            margin-bottom: 0;
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          font-size: $f-12;
          list-style-type: none;
          padding: 0;
          gap: 5px;

          li {
            background-color: $grey;
            padding: $spacing-md;
            height: fit-content;
          }
        }

        .course-link {
          font-size: $f-20;
          align-self: flex-end;
          justify-self: flex-end;
          width: fit-content;
          color: $white;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1063px) {
  .certifications-grid {
    grid-template-columns: repeat(2, minmax(320px, 1fr)) !important;
  }
}

@media only screen and (max-width: 700px) {
  .certifications-grid {
    grid-template-columns: minmax(320px, 400px) !important;

    .certifications-grid-item {
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .certifications-grid {
    grid-template-columns: minmax(300px, 1fr) !important;
  }
}

@media only screen and (max-width: 550px) {
  .certifications-grid {
    padding: 0 $spacing-sm;
    grid-template-columns: minmax(300px, 1fr) !important;
  }
}
