@import "../../../assets/variables.scss";

.footer-container {
  width: 100%;
  background-color: $bg-secondary;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer {
    width: fit-content;
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .follow-links {
      display: flex;
      gap: 15px;
      list-style-type: none;
      margin: $spacing-md 0;
      padding: 0;

      .follow-link {
        font-size: $f-24;
        a {
          color: $white;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}
